import { Popover, ScrollArea, Stack, Button, Group, Text } from '@mantine/core'

type Emoji = {
  id: string
  skins: { native: string; shortcodes: string }[]
}

type EmojiPickerProps = {
  onSelect: (emoji: Emoji) => void
  emojis: Emoji[]
  opened: boolean
  children: React.ReactNode
}

export const EmojiPicker = ({
  onSelect,
  emojis,
  opened,
  children,
}: EmojiPickerProps) => {
  return (
    <Popover
      opened={opened}
      position="top-start"
      transitionProps={{ transition: 'pop' }}
    >
      <Popover.Target>{children}</Popover.Target>
      <Popover.Dropdown>
        <ScrollArea h={120} w={280} scrollbars="y">
          <Stack w={280} align="start">
            {emojis.map((emoji) => (
              <Button
                key={emoji.id}
                onClick={() => onSelect(emoji)}
                fullWidth
                variant="transparent"
                justify="start"
              >
                <Group gap="sm" wrap="nowrap">
                  <Text>{emoji.skins[0].native}</Text>
                  <Text truncate c="dimmed" w="100%">
                    {emoji.skins[0].shortcodes}
                  </Text>
                </Group>
              </Button>
            ))}
          </Stack>
        </ScrollArea>
      </Popover.Dropdown>
    </Popover>
  )
}
