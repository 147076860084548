import {
  Button,
  Group,
  Stack,
  Text,
  Textarea,
  Tooltip,
  Transition,
} from '@mantine/core'
import { GetCustomerChatQuery } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { SEND_MESSAGE_MUTATION } from 'src/graphql/acquisition/lead-management'
import { useEmojiPicker } from 'src/hooks/acquisition/chat'

import { EmojiPicker } from './EmojiPicker'

const REASON_CANT_SEND_MESSAGE = {
  lead_not_owned: 'Oportunidade não está atribuída a você',
  customer_not_interacted_in_last_24_hours:
    'Você não pode enviar mensagens para o cliente se ele não interagiu com o nosso WhatsApp nas últimas 24 horas.',
}

type SendMessageFormProps = {
  customerId: number
  canSendMessage?: GetCustomerChatQuery['GetCustomerChat']['canSendMessage']
  reasonCantSendMessage?: GetCustomerChatQuery['GetCustomerChat']['reasonCantSendMessage']
  suggestions?: GetCustomerChatQuery['GetCustomerChat']['suggestions']
}

const SendMessageForm = ({
  customerId,
  canSendMessage,
  reasonCantSendMessage,
  suggestions = [],
}: SendMessageFormProps) => {
  const { value, setValue, inputRef, emojiResults, insertEmoji, isOpened } =
    useEmojiPicker<HTMLTextAreaElement>()

  const [sendMessage, { loading }] = useMutation(SEND_MESSAGE_MUTATION, {
    refetchQueries: ['GetCustomerChatQuery'],
  })

  const handleSendMessage = (message: string) => {
    if (!message.trim()) return

    sendMessage({
      variables: {
        input: {
          message,
          customerId: Number(customerId),
        },
      },
      onCompleted: () => {
        setValue('')
      },
    })
  }
  return (
    <Stack>
      {!canSendMessage && (
        <Text size="sm" c="red.8" ta="center">
          {
            REASON_CANT_SEND_MESSAGE[
              reasonCantSendMessage as keyof typeof REASON_CANT_SEND_MESSAGE
            ]
          }
        </Text>
      )}

      <Transition
        mounted={suggestions.length > 0}
        transition="pop-bottom-right"
        duration={500}
      >
        {(styles) => (
          <Group justify="flex-end" gap="xs" wrap="nowrap" style={styles}>
            {suggestions.map((suggestion) => (
              <Tooltip
                label={suggestion.content}
                key={suggestion.id}
                multiline
                w="300px"
              >
                <Button
                  key={suggestion.id}
                  size="compact-xs"
                  fw="normal"
                  color="violet"
                  variant="light"
                  ta="center"
                  onClick={() => {
                    return setValue(suggestion.content)
                  }}
                  style={{ cursor: 'pointer' }}
                  maw="150px"
                >
                  <Text size="sm" truncate="end">
                    {suggestion.content}
                  </Text>
                </Button>
              </Tooltip>
            ))}
          </Group>
        )}
      </Transition>
      <Group gap="sm" pos="relative">
        <EmojiPicker
          onSelect={insertEmoji}
          emojis={emojiResults}
          opened={isOpened}
        >
          <Textarea
            ref={inputRef}
            autosize
            placeholder={
              canSendMessage
                ? 'Digite uma mensagem...'
                : REASON_CANT_SEND_MESSAGE[
                    reasonCantSendMessage as keyof typeof REASON_CANT_SEND_MESSAGE
                  ]
            }
            value={value}
            onChange={(e) => {
              setValue(e.currentTarget.value)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if (e.shiftKey || e.altKey) {
                  // Allow shift and alt key to enter new lines
                  return
                }
                e.preventDefault()
                handleSendMessage(value)
              }
            }}
            style={{ flex: 1 }}
            disabled={!canSendMessage}
          />
        </EmojiPicker>
        <Button
          onClick={() => handleSendMessage(value)}
          loading={loading}
          disabled={loading || !canSendMessage}
        >
          Enviar
        </Button>
      </Group>
    </Stack>
  )
}

export default SendMessageForm
