import {
  ActionIcon,
  Avatar,
  Badge,
  Divider,
  Group,
  List,
  Stack,
  Title,
  Tooltip,
  Text,
  Skeleton,
} from '@mantine/core'
import {
  IconEdit,
  IconEye,
  IconMapPin,
  IconSparkles,
  IconTarget,
} from '@tabler/icons-react'
import { format } from 'date-fns'
import { GetLeadQuery, GetLeadQueryVariables, Lead } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { GET_LEAD_BY_ID } from 'src/graphql/acquisition/lead-management'
import { mask } from 'src/helpers/mask'

import { LeadActions } from './LeadActions'

type LeadInfoSidebarProps = {
  leadId: number
  openEditingModal?: () => void
}

const LoadingLeadInfoSidebar = () => {
  return (
    <Stack
      pb={100}
      bg="gray.1"
      justify="stretch"
      flex={{
        base: 1,
        md: 0.5,
        lg: 1,
      }}
    >
      <Stack p="md">
        <Group align="center" mb="md" wrap="nowrap">
          <Skeleton height={64} circle />
          <Stack gap="xs">
            <Skeleton height={28} width={200} />
          </Stack>
        </Group>

        <Group gap="md" mb="md" justify="space-between">
          <Stack align="flex-start" gap={4}>
            <Skeleton height={16} width={100} />
            <Skeleton height={16} width={120} />
          </Stack>
        </Group>

        <Group gap="md" mb="md">
          <Stack align="flex-start" gap={4}>
            <Skeleton height={16} width={80} />
            <Skeleton height={16} width={100} />
          </Stack>

          <Stack align="flex-start" gap={4}>
            <Skeleton height={16} width={80} />
            <Skeleton height={16} width={100} />
          </Stack>
        </Group>

        <Stack gap="md">
          <Group>
            <Skeleton height={20} width={80} />
          </Group>

          {[1, 2, 3].map((i) => (
            <Stack key={i} gap={4}>
              <Skeleton height={16} width={120} />
              <Skeleton height={16} width={180} />
            </Stack>
          ))}

          <Divider />

          <Group gap="xs">
            <Skeleton height={20} width={100} />
          </Group>

          {[1, 2, 3, 4].map((i) => (
            <Stack key={i} gap={4}>
              <Skeleton height={16} width={140} />
              <Skeleton height={16} width={160} />
            </Stack>
          ))}

          <Divider />

          <Group gap="xs">
            <Skeleton height={20} width={120} />
          </Group>

          {[1, 2, 3].map((i) => (
            <Stack key={i} gap={4}>
              <Skeleton height={16} width={130} />
              <Skeleton height={16} width={170} />
            </Stack>
          ))}

          <Divider />

          <Group gap="xs">
            <Skeleton height={20} width={100} />
          </Group>

          {[1, 2, 3, 4].map((i) => (
            <Stack key={i} gap={4}>
              <Skeleton height={16} width={150} />
              <Skeleton height={16} width={180} />
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  )
}

export function LeadInfoSidebar({
  leadId,
  openEditingModal,
}: LeadInfoSidebarProps) {
  const { data, loading } = useQuery<GetLeadQuery, GetLeadQueryVariables>(
    GET_LEAD_BY_ID,
    {
      variables: { id: leadId },
    }
  )

  if (loading) return <LoadingLeadInfoSidebar />

  const lead = data?.GetLead

  return (
    <Stack
      pb={100}
      bg="gray.1"
      justify="stretch"
      flex={{
        base: 1,
        md: 0.5,
        lg: 1,
      }}
    >
      <Stack p="md">
        <Group align="center" mb="md" wrap="nowrap">
          <Avatar
            size={'lg'}
            name={lead.customer.name}
            color="initials"
            variant="outline"
          />

          <Stack gap="xs">
            <Title order={3}>{lead.customer.name}</Title>
          </Stack>
        </Group>

        <Group gap="md" mb="md" justify="space-between">
          <Stack align="flex-start" gap={4}>
            <Text size="xs" c="dark.2">
              Atendido por
            </Text>
            {lead.owner && (
              <Text size="xs" c="dark.3">
                {lead.owner?.name}
              </Text>
            )}
            {lead.owner === null && (
              <Text c="red.9" fz="sm">
                Aguardando atendimento
              </Text>
            )}
          </Stack>

          <LeadActions lead={lead as Lead} />
          {lead.status === 'FINISHED' && (
            <Badge color="green">Finalizado</Badge>
          )}
        </Group>

        <Group gap="md" mb="md">
          <Stack align="flex-start" gap={4}>
            <Text size="xs" c="dark.2">
              Criado em
            </Text>
            <Text size="xs" c="dark.3">
              {format(new Date(lead.createdAt), 'dd/MM/yyyy hh:mm')}
            </Text>
          </Stack>

          <Stack align="flex-start" gap={4}>
            <Text size="xs" c="dark.2">
              Última Atualização
            </Text>
            <Text size="xs" c="dark.3">
              {format(new Date(lead.updatedAt), 'dd/MM/yyyy hh:mm')}
            </Text>
          </Stack>
        </Group>

        <Stack gap="md">
          {lead.summary && (
            <>
              <Group gap="xs">
                <Title order={5}>Resumo</Title>

                <IconSparkles size={16} />
              </Group>

              <Text size="xs">{lead.summary}</Text>

              <Divider />
            </>
          )}

          <Group>
            <Title order={5}>Dados</Title>

            {openEditingModal && (
              <Tooltip label="Editar dados">
                <ActionIcon
                  size="sm"
                  radius="sm"
                  fz="xs"
                  color="dark"
                  variant="outline"
                  onClick={() => openEditingModal()}
                >
                  <IconEdit size={16} />
                </ActionIcon>
              </Tooltip>
            )}
          </Group>

          <Stack gap={4}>
            <Text c="dark.2" size="sm">
              Nome
            </Text>
            <Text size="sm">{lead.customer.name}</Text>
          </Stack>

          <Stack gap={4}>
            <Text c="dark.2" size="sm">
              Email
            </Text>
            <Text size="sm">{lead.customer.email}</Text>
          </Stack>

          <Stack gap={4}>
            <Text c="dark.2" size="sm">
              Telefone
            </Text>
            <Text size="sm">{mask(lead.customer.phone, 'phone')}</Text>
          </Stack>

          <Divider />

          <Group gap="xs">
            <Title order={5}>Interesse</Title>

            <IconEye size={16} />
          </Group>

          <Stack gap={4}>
            <Text c="dark.2" size="sm">
              O curso é para o próprio lead?
            </Text>
            <Text size="sm">{lead.product?.forMe ? 'Sim' : 'Não'}</Text>
          </Stack>

          <Stack gap={4}>
            <Text c="dark.2" size="sm">
              Idioma
            </Text>
            <Text size="sm">{lead.product?.idiom || 'Não informado'}</Text>
          </Stack>

          <Stack gap={4}>
            <Text c="dark.2" size="sm">
              Faixa Etária
            </Text>
            <Text size="sm">{lead.product?.ageGroup || 'Não informado'}</Text>
          </Stack>

          <Stack gap={4}>
            <Text c="dark.2" size="sm">
              Nível de Conhecimento
            </Text>
            <Text size="sm">
              {lead.product?.knowledgeLevel || 'Não informado'}
            </Text>
          </Stack>

          <Stack gap={4}>
            <Text c="dark.2" size="sm">
              Interesse
            </Text>
            <Text size="sm">{lead.extra?.trigger || 'Não informado'}</Text>
          </Stack>

          <Divider />

          <Group gap="xs">
            <Title order={5}>Localização</Title>

            <IconMapPin size={16} />
          </Group>

          <Stack gap={4}>
            <Text c="dark.2" size="sm">
              Escola selecionada
            </Text>
            <Text size="sm">{lead.school}</Text>
          </Stack>

          <Stack gap={4}>
            <Text c="dark.2" size="sm">
              Território
            </Text>
            <Text size="sm">{lead.location?.territoryName}</Text>
          </Stack>

          <Stack gap={4}>
            <Text c="dark.2" size="sm">
              Escolas próximas
            </Text>
            <Text size="sm">
              {!lead.location?.nearbyFranchises?.length &&
                'Nenhuma escola próxima informada'}

              {lead.location?.nearbyFranchises?.length && (
                <List type="ordered" size="sm">
                  {lead.location?.nearbyFranchises?.map((franchise) => (
                    <List.Item key={franchise.id}>
                      <Text>{franchise.name}</Text>
                    </List.Item>
                  ))}
                </List>
              )}
            </Text>
          </Stack>

          <Divider />

          <Group gap="xs">
            <Title order={5}>Marketing</Title>

            <IconTarget size={16} />
          </Group>

          <Stack gap={4}>
            <Text c="dark.2" size="sm">
              Página de origem
            </Text>
            <Text size="sm">{lead.extra?.origin || 'Não informado'}</Text>
          </Stack>

          <Stack gap={4}>
            <Text c="dark.2" size="sm">
              Nome da Campanha
            </Text>
            <Text size="sm">{lead.extra?.utmCampaign || 'Não informado'}</Text>
          </Stack>

          <Stack gap={4}>
            <Text c="dark.2" size="sm">
              Origem da Campanha
            </Text>
            <Text size="sm">{lead.extra?.utmSource || 'Não informado'}</Text>
          </Stack>

          <Stack gap={4}>
            <Text c="dark.2" size="sm">
              Mídia da Campanha
            </Text>
            <Text size="sm">{lead.extra?.utmMedium || 'Não informado'}</Text>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
