import 'dayjs/locale/pt-br'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)
dayjs.locale('pt-BR')

export const formatDate = (date: string, format: string = 'DD/MM/YYYY HH:mm') =>
  dayjs(date).format(format)
export const formatDateRelative = (date: string) => dayjs(date).fromNow()

export { dayjs }
