export const LIST_CUSTOMER_NOTES = gql`
  query ListCustomerNotes($customerId: Int!) {
    ListCustomerNotes(customerId: $customerId) {
      id
      salesConsultant {
        name
      }
      note
      createdAt
    }
  }
`

export const CREATE_CUSTOMER_NOTE = gql`
  mutation CreateCustomerNote($input: CreateCustomerNoteInput!) {
    CreateCustomerNote(input: $input) {
      id
    }
  }
`
