import dompurify from 'dompurify'

import { useWhatsAppFormatting } from 'src/hooks/acquisition/chat'

import styles from './FormattedMessage.module.css'

export const FormattedMessage = ({ message }: { message: string }) => {
  const { formatMessage } = useWhatsAppFormatting()
  const dirty = formatMessage(message)

  const sanitized = dompurify.sanitize(dirty, {
    ALLOWED_TAGS: ['pre', 'code', 'strong', 'em', 'del', 'blockquote', 'li'],
  })

  return (
    <div
      className={styles.message}
      dangerouslySetInnerHTML={{
        __html: sanitized,
      }}
    />
  )
}
