import { useEffect } from 'react'

import {
  ActionIcon,
  Code,
  Divider,
  Group,
  List,
  Loader,
  Modal,
  Stack,
  Tabs,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import {
  IconBrandWhatsapp,
  IconClock,
  IconInfoCircle,
  IconNote,
} from '@tabler/icons-react'
import {
  GetLeadQuery,
  GetLeadQueryVariables,
  MarkNotificationAsReadMutation,
  MarkNotificationAsReadMutationVariables,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import { notifications } from 'src/components/shared/Notifications'
import { GET_LEAD_BY_ID } from 'src/graphql/acquisition/lead-management'
import {
  LIST_NOTIFICATIONS_QUERY,
  MARK_NOTIFICATION_AS_READ_MUTATION,
} from 'src/graphql/acquisition/lead-notifications'

import { Chat } from './Chat'
import { CustomerEventsTimeline } from './CustomerEventsTimeline'
import CustomerForm from './CustomerForm'
import CustomerNotes from './CustomerNotes'
import { LeadInfoSidebar } from './LeadInfoSidebar'

type ViewLeadProps = {
  id: number
}

function ViewLead({ id }: ViewLeadProps) {
  const { data, loading } = useQuery<GetLeadQuery, GetLeadQueryVariables>(
    GET_LEAD_BY_ID,
    {
      variables: { id },
    }
  )
  const [markLeadAsRead] = useMutation<
    MarkNotificationAsReadMutation,
    MarkNotificationAsReadMutationVariables
  >(MARK_NOTIFICATION_AS_READ_MUTATION, {
    refetchQueries: [LIST_NOTIFICATIONS_QUERY],
  })
  const [
    whatsappInstructionsOpen,
    { open: openWhatsappInstructions, close: closeWhatsappInstructions },
  ] = useDisclosure(false)

  useEffect(() => {
    markLeadAsRead({ variables: { input: { leadId: id } } })
  }, [data])

  const [
    openedEditingModal,
    { open: openEditingModal, close: closeEditingModal },
  ] = useDisclosure(false)

  if (loading) {
    return (
      <Group p="md" justify="center" align="center" h="100%">
        <Loader size="xl" />
      </Group>
    )
  }

  const lead = data.GetLead

  const _handleSuccess = () => {
    notifications.success({
      title: 'Transferência realizada',
      message: 'Atendimento transferido com sucesso!',
    })
  }

  return (
    <>
      <Modal
        opened={openedEditingModal}
        onClose={closeEditingModal}
        title="Editando dados do cliente"
        centered
        overlayProps={{ blur: 4 }}
      >
        <Stack gap="md">
          <CustomerForm
            data={lead.customer}
            onSuccess={() => {
              closeEditingModal()
            }}
          />
        </Stack>
      </Modal>

      <Group wrap="nowrap" align="stretch" mih="calc(100vh - 100px)">
        <LeadInfoSidebar leadId={lead.id} openEditingModal={openEditingModal} />

        <Stack
          py="sm"
          gap={0}
          h="calc(100vh - 160px)"
          style={{
            position: 'sticky',
            top: 0,
            scrollPaddingTop: '100px',
          }}
          flex={{
            base: 1,
            md: 1.5,
            lg: 2,
          }}
        >
          <Group gap="xs">
            <ThemeIcon
              size="lg"
              radius="sm"
              color="dark.2"
              variant="transparent"
            >
              <IconBrandWhatsapp size={24} />
            </ThemeIcon>

            <Title order={4}>Conversa</Title>

            {process.env.NODE_ENV !== 'production' && (
              <>
                <Modal
                  opened={whatsappInstructionsOpen}
                  onClose={closeWhatsappInstructions}
                  title="Como testar Whatsapp"
                  size="lg"
                >
                  <Text>
                    Para testar o Whatsapp, você precisa dos seguintes passos:
                  </Text>

                  <List type="ordered" my="md">
                    <List.Item>
                      <Text>Baixe o Whatsapp no seu celular</Text>
                    </List.Item>
                    <List.Item>
                      <Text>
                        Envie a seguinte mensagem para o número{' '}
                        <a
                          href={`https://wa.me/${process.env.TWILIO_FROM_NUMBER}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {process.env.TWILIO_FROM_NUMBER}
                        </a>
                        :
                      </Text>

                      <Code c="white" color="dark.7">
                        join differ-six
                      </Code>
                    </List.Item>
                    <List.Item>
                      <Text>
                        Aguarde a resposta do Twilio. Após a confirmação, você
                        pode enviar mensagens por 24h.
                      </Text>
                    </List.Item>
                  </List>
                </Modal>
                <ActionIcon
                  size="sm"
                  radius="sm"
                  color="dark.3"
                  variant="transparent"
                  onClick={openWhatsappInstructions}
                >
                  <IconInfoCircle size={18} />
                </ActionIcon>
              </>
            )}
          </Group>

          <Divider mt="xs" p={0} />

          <Chat customerId={lead.customer.id} />
        </Stack>

        <Stack
          bg="gray.1"
          p="sm"
          mih="calc(100vh - 100px)"
          flex={{
            base: 1,
            md: 0.5,
            lg: 1,
          }}
        >
          <Tabs defaultValue="timeline">
            <Tabs.List>
              <Tabs.Tab value="timeline" leftSection={<IconClock size={16} />}>
                Eventos
              </Tabs.Tab>
              <Tabs.Tab
                value="customerNotes"
                leftSection={<IconNote size={16} />}
              >
                Anotações
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="timeline">
              <CustomerEventsTimeline customerId={lead.customer.id} />
            </Tabs.Panel>

            <Tabs.Panel value="customerNotes">
              <CustomerNotes customerId={lead.customer.id} />
            </Tabs.Panel>
          </Tabs>
        </Stack>
      </Group>
    </>
  )
}

export default ViewLead
