import {
  Box,
  Center,
  Group,
  Image,
  rem,
  Stack,
  ThemeIcon,
  Tooltip,
  UnstyledButton,
} from '@mantine/core'
import {
  IconBriefcase,
  IconChartBar,
  IconGauge,
  IconGridDots,
  IconHome2,
  IconLogout,
  IconMessages,
  IconTargetArrow,
  IconUsers,
} from '@tabler/icons-react'

import { Link, useLocation } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import { NotificationsSidebar } from 'src/components/acquisition/NotificationsSidebar/NotificationsSidebar'
import { ViewLeadProvider } from 'src/providers/acquisition/lead/ViewLeadProvider'

import classes from './AcquisitionLayout.module.css'

interface NavbarLinkProps {
  icon: typeof IconHome2
  label: string
  active?: boolean
  url?: string
  disabled?: boolean
  onClick?: () => void
}

function NavbarLink({
  icon: Icon,
  label,
  active,
  url,
  onClick,
  disabled = false,
}: NavbarLinkProps) {
  return (
    <Tooltip label={label} position="right" transitionProps={{ duration: 0 }}>
      <UnstyledButton
        component={Link}
        {...(!disabled && { to: url, onClick })}
        aria-disabled={disabled}
        className={classes.link}
        data-active={active || undefined}
        style={{ cursor: disabled ? 'default' : 'pointer' }}
      >
        <ThemeIcon color={disabled ? 'gray.4' : 'dark.9'} variant="transparent">
          <Icon size={20} stroke={1.5} />
        </ThemeIcon>
      </UnstyledButton>
    </Tooltip>
  )
}

const mockdata = [
  {
    icon: IconGauge,
    label: 'Em breve',
    url: '/acquisition/dashboard',
    disabled: true,
  },
  { icon: IconBriefcase, label: 'Oportunidades', url: '/acquisition/leads' },
  { icon: IconMessages, label: 'Conversas', url: '/acquisition/chats' },
  { icon: IconUsers, label: 'Clientes', url: '/acquisition/customers' },
  {
    icon: IconTargetArrow,
    label: 'Em breve',
    url: '/acquisition/campaigns',
    disabled: true,
  },
  {
    icon: IconChartBar,
    label: 'Relatórios',
    url: '/acquisition/reports',
  },
]

type AcquisitionLayoutProps = {
  children?: React.ReactNode
}

export function AcquisitionLayout({ children }: AcquisitionLayoutProps) {
  const { pathname } = useLocation()
  const { logOut } = useAuth()

  const links = mockdata.map((link, k) => (
    <NavbarLink
      {...link}
      url={link.url}
      key={k}
      active={pathname === link.url}
    />
  ))

  return (
    <ViewLeadProvider>
      <Group wrap="nowrap" align="flex-start">
        <nav className={classes.navbar}>
          <Stack justify="space-between" h="100vh">
            <Stack align="center" gap="lg">
              <Center>
                <Image
                  src="/cna-logo-minimal.svg"
                  alt="CNA Logo"
                  height={rem(24)}
                />
              </Center>

              <NotificationsSidebar />
            </Stack>

            <div>
              <Stack justify="center" gap={0}>
                {links}
              </Stack>
            </div>

            <Stack justify="center" gap={0}>
              <NavbarLink icon={IconGridDots} label="Contextos" />

              <NavbarLink icon={IconLogout} label="Logout" onClick={logOut} />
            </Stack>
          </Stack>
        </nav>

        <>
          <Box pos="relative" left={80} flex={1} maw="calc(100% - 80px)">
            {children}
          </Box>
        </>
      </Group>
    </ViewLeadProvider>
  )
}

export default AcquisitionLayout
