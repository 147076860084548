import { useContext, useState, useEffect } from 'react'

import { Card, Title, Button, rem } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { ListActivities, ActivityAnswerField } from 'types/graphql'

import { useParams } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { notifications } from 'src/components/shared/Notifications'
import { CREATE_ANSWER_ACTIVITY } from 'src/graphql/learning/learning'
import {
  formatAnswerFieldsInput,
  addAnswerToActivity,
} from 'src/helpers/activity'
import { useIsSmallScreen } from 'src/hooks/shared/screen'
import { ActivityContext } from 'src/providers/learning/activity/ActivityProvider'
import { useActivityResolver } from 'src/providers/learning/activity/ActivityResolverProvider'

import ActivityFeedback from '../ActivityFeedback/ActivityFeedback'
import CorrectAnswer from '../CorrectAnswer/CorrectAnswer'

import styles from './ActivityResult.module.css'

const ActivityResult = () => {
  const { lessonId } = useParams()
  const isSmallScreen = useIsSmallScreen()

  const { setAnswerFeedback, answer, answerFeedback, answerThreshold } =
    useActivityResolver()

  const { attempts } = answerFeedback
  const { isFeedbackVisible, isCorrectAnswerVisible } = answerFeedback
  const { t } = useTranslation('learning')
  const { activity, activitiesList, setActivitiesList } =
    useContext(ActivityContext)

  const { required } = activitiesList || {}
  const [submitEnabled, setSubmitEnabled] = useState(false)

  const [createAnswerActivity, { loading }] = useMutation(
    CREATE_ANSWER_ACTIVITY
  )

  const { typology } = activity || {}
  const isWriting = typology === 'WRITING'

  const showError = () => {
    notifications.error({
      title: 'Erro ao salvar resposta',
      message: `Por favor tente novamente.`,
    })
  }

  const submitAnswer = async () => {
    try {
      const userAnswerFields = formatAnswerFieldsInput(activity, answer)

      const response = await createAnswerActivity({
        variables: {
          lessonId,
          userAnswerFields,
        },
      })

      const { data } = response
      const { AnswerActivity } = data

      if (AnswerActivity && AnswerActivity?.feedback) {
        const feedback = {
          isFeedbackVisible: true,
          type: AnswerActivity.feedback.type,
          title_en: AnswerActivity.feedback.title_en,
          title_pt_br: AnswerActivity.feedback.title_pt_br,
          explanation_en: AnswerActivity.feedback.explanation_en,
          explanation_pt_br: AnswerActivity.feedback.explanation_pt_br,
          attempts: attempts + 1,
          language: 'en',
        }

        setAnswerFeedback(feedback)

        const activityType = required?.some((item) => item.id === activity.id)
          ? 'required'
          : 'extra'

        updateActivityList(activityType, AnswerActivity)
      } else {
        showError()
      }
    } catch {
      showError()
    }
  }

  const updateActivityList = (
    activityType: 'required' | 'extra',
    AnswerActivity: ActivityAnswerField
  ) => {
    const list = activitiesList?.[activityType] || []
    const activityMatch = list.find((item) => item.id === activity.id)

    if (!activityMatch) return

    const newActivityHit = addAnswerToActivity(
      activityMatch,
      AnswerActivity?.answers
    )

    const updateActivities = {
      ...activitiesList,
      [activityType]: list.map((activity) =>
        activity.id === newActivityHit.id ? newActivityHit : activity
      ),
    }

    setActivitiesList(updateActivities as ListActivities)
  }

  useEffect(() => {
    if (!answer || !activity || !answerThreshold) return

    const { typology, answerFields } = activity

    let shouldSubmit = answer.length >= answerThreshold

    if (typology === 'WRITING') {
      const hasPreviousAnswer = answerFields[0]?.answers?.length > 0

      if (hasPreviousAnswer) {
        shouldSubmit = false
      }
    }

    setSubmitEnabled(shouldSubmit)
  }, [answer, answerThreshold, activity])

  return (
    <Card
      withBorder
      radius={isSmallScreen ? 0 : rem(6)}
      styles={{
        root: {
          boxShadow: isSmallScreen
            ? `0 ${rem(-4)} ${rem(6)} 0 var(--mantine-color-gray-2)`
            : 'none',
        },
      }}
      data-sel="result"
    >
      {!isFeedbackVisible && (
        <>
          <Title order={5} pb={rem(16)} visibleFrom="md">
            {t('main.result')}
          </Title>
          {isCorrectAnswerVisible && <CorrectAnswer />}
          <Button
            onClick={submitAnswer}
            size="lg"
            fullWidth
            disabled={!submitEnabled}
            c="white"
            bg={
              submitEnabled ? (isWriting ? 'cnaBlue.6' : 'cnaRed.6') : 'dark.1'
            }
            radius="lg"
            className={
              isWriting
                ? styles.activitySendTeacherButton
                : styles.activityResultButton
            }
            loading={loading}
            data-sel="submit-answer"
          >
            {isWriting ? t('main.send-to-teacher') : t('main.check-answer')}
          </Button>
        </>
      )}
      <ActivityFeedback />
    </Card>
  )
}

export default ActivityResult
