import { useState, useEffect, useRef, useMemo } from 'react'

import data from '@emoji-mart/data'
import { useDisclosure } from '@mantine/hooks'
import { init, SearchIndex } from 'emoji-mart'

type Emoji = {
  id: string
  name: string
  keywords: string[]
  skins: {
    unified: string
    native: string
    shortcodes: string
  }[]
  version: number
  search: string
}

init({ data })

export const useEmojiPicker = <T extends HTMLElement>() => {
  const [value, setValue] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const inputRef = useRef<T>(null)

  const [emojiResults, setEmojiResults] = useState<Emoji[]>([])
  const [isOpened, { open: showPicker, close: hidePicker }] = useDisclosure()

  useEffect(() => {
    const match = value.match(/:(\w+)$/)

    if (!match) hidePicker()

    const term = match?.[1] || ''
    setSearchTerm(term)

    if (term.length === 0) return

    SearchIndex.search(term).then((emojis: Emoji[]) => {
      if (!emojis || emojis.length === 0) return hidePicker()

      setEmojiResults(emojis)
      showPicker()
    })
  }, [value, showPicker, hidePicker])

  const insertEmoji = (emoji: Emoji) => {
    if (searchTerm) {
      const newText = value.replace(/:\w+$/, emoji.skins[0].native)
      setValue(newText)
    } else {
      setValue((prev) => prev + emoji.skins[0].native)
    }

    inputRef.current?.focus()
    hidePicker()
  }

  return { value, setValue, isOpened, emojiResults, insertEmoji, inputRef }
}

//https://gist.github.com/elfefe/ef08e583e276e7617cd316ba2382fc40
const WHATSAPP_FORMATTING_RULES = [
  { regex: /```([\s\S]+?)```/g, replacement: '<pre>$1</pre>' }, // Monospace block
  { regex: /`([^`]+)`/g, replacement: '<code>$1</code>' }, // Inline code
  { regex: /\*(.*?)\*/g, replacement: '<strong>$1</strong>' }, // Bold with *
  { regex: /_(.*?)_/g, replacement: '<em>$1</em>' }, // Italic with _
  { regex: /~(.*?)~/g, replacement: '<del>$1</del>' }, // Strikethrough with ~
  { regex: /^> (.*$)/gm, replacement: '<blockquote>$1</blockquote>' }, // Blockquote
  {
    regex: /^\s*(\d+)\.\s+(.+)$/gm,
    replacement: '<li class="list-item">$1. $2</li>',
  }, // Ordered list
  {
    regex: /^\s*[-+*]\s+(.+)$/gm,
    replacement: '<li class="list-item">• $1</li>',
  }, // Unordered list
]

/**
 * Hook to format WhatsApp-like messages
 */
export const useWhatsAppFormatting = () => {
  const formatMessage = (message: string) => {
    if (!message) return message

    return WHATSAPP_FORMATTING_RULES.reduce(
      (formattedText, rule) =>
        formattedText.replace(rule.regex, rule.replacement),
      message
    )
  }

  const getStyledPreview = (message: string) => {
    return useMemo(() => {
      return { __html: formatMessage(message) }
    }, [message])
  }

  return { formatMessage, getStyledPreview }
}
