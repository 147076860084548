import { AppShell, rem } from '@mantine/core'
import { IconHome, IconSchool } from '@tabler/icons-react'

import { routes, useLocation } from '@redwoodjs/router'

import FooterMenu from 'src/components/learning/FooterMenu'
import Header from 'src/components/learning/Header/Header'
import TeacherProfileMenu from 'src/components/learning/TeacherProfileMenu'
import { useIsSmallScreen } from 'src/hooks/shared/screen'
type props = {
  children?: React.ReactNode
}

export const menu = [
  {
    label: 'My Classes',
    route: '/learning/teacher/classes',
  },
  {
    label: "Teacher's Packs",
    route: '/learning/teacher/guides/levels',
  },
]

const footerMenu = [
  {
    label: 'My Classes',
    route: '/learning/teacher/classes',
    icon: <IconHome size={20} />,
  },
  {
    label: "Teacher's Packs",
    route: '/learning/teacher/guides/levels',
    icon: <IconSchool size={20} />,
  },
]

const LearningTeacherLayout = ({ children }: props) => {
  const isSmallScreen = useIsSmallScreen()
  const { pathname } = useLocation()
  const isTestSchedulePage = /^\/learning\/teacher\/test-schedule\/\d+$/.test(
    pathname
  )

  return (
    <AppShell bg={isSmallScreen ? 'white' : 'color-body'}>
      <AppShell.Main pb={isSmallScreen ? rem(120) : rem(20)} className="main">
        <Header menu={menu} logoTo={routes.learningTeacherClassesPage()}>
          <TeacherProfileMenu />
        </Header>
        {children}
      </AppShell.Main>
      {isSmallScreen && !isTestSchedulePage && (
        <AppShell.Footer>
          <FooterMenu menu={footerMenu} />
        </AppShell.Footer>
      )}
    </AppShell>
  )
}

export default LearningTeacherLayout
